import React from 'react'
import '../static/recommend.scss'

function Recommendation() {
  return (
    <>
        <div className='recommend-body'>


        </div>
    </>
  )
}

export default Recommendation